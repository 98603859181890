<template>
  <div :class="$style.BonusInfo">
    <div :class="$style.wrapper">
      <a :href="$configData.addwine_link + 'bonus-info'" target="_blank">
        <el-button size="small" type="primary">Просмотреть</el-button>
      </a>
      <el-button
        size="small"
        type="primary"
        :class="$style.button"
        @click="submitForm()"
        >Сохранить контент</el-button
      >
    </div>
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-position="right"
      label-width="12.5rem"
      :class="$style.form"
    >
      <h2 :class="$style.name">Страница: Бонусная система</h2>
      <div :class="$style.forSeoTexts">
        <el-form-item label="Заголовок" prop="title">
          <el-input v-model="form.title" />
        </el-form-item>
        <el-form-item label="SEO-тексты">
          <SeoTextsBlock :seoTexts="form.seoTexts" @getSeo="getSeoTexts" />
        </el-form-item>
        <el-form-item label="SEO-FAQ">
          <SeoFaqsBlock :seoFAQs="form.seoFaqsIds" @getSeo="getSeoFaqs" />
        </el-form-item>
      </div>

      <h3>Контент секции</h3>
      <el-button
        size="small"
        type="primary"
        :class="$style.button"
        @click="addSection()"
        >Добавить секцию</el-button
      >
      <h5>
        bonusWriteOffRatio - коэффициент списания (текущий -
        {{ paymentSettings.bonusWriteOffRatio }})
      </h5>
      <h5>
        bonusExpiration - количество дней до сгорания (текущий -
        {{ paymentSettings.bonusExpiration }})
      </h5>
      <h5>
        bonusAccrualRatio - коэффициент начисления (текущий -
        {{ paymentSettings.bonusAccrualRatio * 100 }})%
      </h5>
      <h5>
        bonusMaxWriteOffCount - макс. списание (% от заказа) (текущий -
        {{ paymentSettings.bonusMaxWriteOffCount }})
      </h5>
      <h5>
        Чтобы добавить в текст указанные параметры оберниче их в двойные скобки
        (пример: {bonusMaxWriteOffCount})
      </h5>
      <Container non-drag-area-selector=".not-draggable" @drop="onDrop">
        <Draggable v-for="(section, index) in form.sections" :key="index">
          <div
            :class="[
              'draggable-item',
              { 'not-draggable': form.sections?.length < 2 },
            ]"
          >
            <el-form-item label="Заголовок" :prop="'title' + index">
              <el-input v-model="section.title" />
            </el-form-item>
            <div :class="$style.sectionBlock">
              <div :class="$style.sectionItem">
                <Icon
                  v-if="form.sections?.length > 1"
                  name="draggable"
                  :class="$style.icon"
                />
                <TextEditor
                  :value.sync="section.text"
                  :is-image="false"
                  :is-media="false"
                  :height="400"
                />
              </div>

              <div :class="$style.removeElem">
                <el-button
                  type="danger"
                  plain
                  circle
                  icon="el-icon-delete"
                  :class="$style.remove"
                  @click="removeSection(index)"
                />
              </div>
            </div>
          </div>
        </Draggable>
      </Container>

      <SeoBlock
        :title.sync="form.seo.title"
        :description.sync="form.seo.description"
        :og-title.sync="form.seo.ogTitle"
        :og-description.sync="form.seo.ogDescription"
        :og-image.sync="form.seo.ogImage"
        :class="$style.seo"
      />
    </el-form>
  </div>
</template>

<script>
import delivery from '@/delivery'
import SeoBlock from '@/components/moleculs/SEO.vue'
import SeoTextsBlock from '@/components/organisms/SeoTextsBlock.vue'
import SeoFaqsBlock from '@/components/organisms/SeoFaqsBlock.vue'
import TextEditor from '@/components/atoms/TextEditor.vue'
import Icon from '@/components/atoms/Icon'

export default {
  components: {
    SeoBlock,
    TextEditor,
    Icon,
    SeoTextsBlock,
    SeoFaqsBlock,
  },
  async created() {
    await this.getPaymentSettings()
    await this.getContent()
  },

  data() {
    return {
      paymentSettings: {
        bonusAccrualRatio: '',
        bonusExpiration: '',
        bonusMaxWriteOffCount: '',
        bonusWriteOffRatio: '',
      },
      form: {
        title: '',
        seoTexts: [],
        sections: [],
        seoFaqsIds: [],
        seo: {
          title: '',
          description: '',
          ogTitle: '',
          ogDescription: '',
          ogImage: '',
        },
      },
      rules: {
        title: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        section: {
          title: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          text: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
        },
        seo: {
          title: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          description: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogTitle: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogDescription: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogImage: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'change',
            },
          ],
        },
      },
    }
  },
  methods: {
    async getPaymentSettings() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.PaymentServiceCore.PaymentSettingsActions.get()
      loading.close()

      if (error) return

      this.paymentSettings = value
    },
    getSeoTexts(seoTexts) {
      this.form.seoTexts = seoTexts
    },
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return arr

      const result = [...arr]
      let itemToAdd = payload

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
      }

      return result
    },
    onDrop(dropResult) {
      this.form.sections = this.applyDrag(this.form.sections, dropResult)
    },
    addSection() {
      this.form.sections.push({
        image: '',
        text: '',
      })
    },
    removeSection(index) {
      this.form.sections.splice(index, 1)
    },
    uploadImages(images, index) {
      this.form.sections[index].image = images.length ? images[0].original : ''
    },

    async getContent() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.ContentServiceCore.PagesActions.get(
          'bonus-info',
          'addwine',
        )
      loading.close()

      if (error) return

      value.content.seoTexts =
        value.content?.seoTexts?.map((seoText) => seoText.id) ?? []

      value.content.seoFaqsIds =
        value.content?.seoFaqs?.map((seoFaq) => seoFaq.id) ?? []

      this.form = value.content
    },
    getSeoFaqs(seoFaqs) {
      this.form.seoFaqsIds = seoFaqs
    },
    async submitForm() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
          })

          const seoTexts = this.form.seoTexts?.map((seoText) => ({
            id: seoText,
          }))
          const seoFaqs = this.form.seoFaqsIds?.map((seoFaq) => ({
            id: seoFaq,
          }))

          const data = {
            ...this.form,
            seoTexts,
            seoFaqs,
          }

          const result = await delivery.ContentServiceCore.PagesActions.update(
            'bonus-info',
            'addwine',
            data,
          )
          loading.close()

          if (result.error) {
            this.$message({
              message: 'Ошибка редактирования страницы',
              type: 'error',
            })
            return
          }

          this.$message({
            message: 'Страница успешно отредактирована',
            type: 'success',
          })

          this.getContent()
        }
      })
    },
  },
}
</script>

<style lang="scss" module>
.BonusInfo {
  padding: 0 1rem;
  .wrapper {
    @include stickyWrapper;
    .button {
      margin-left: 1rem;
    }
  }
  .icon {
    width: 2.5rem;
    height: 2.5rem;
  }

  .form {
    h2 {
      text-align: center;
      margin-bottom: 3rem;
    }
    h3 {
      margin-bottom: 1rem;
    }
    .forSeoTexts {
      .seoTexts {
        width: 100%;
      }
    }
    .sectionBlock {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      padding: 1rem 0;

      .sectionItem {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
      .removeElem {
        width: 1.875rem;
        height: 1.875rem;
        padding: 0 1rem;
      }
    }

    .seo {
      & > div > label {
        text-align: left;
      }
    }
  }
}
</style>
